<template>
  <!--begin::Container-->
  <div class="wrapper">
    <div class="section-shaped my-0 skew-separator skew-mini ">
      <v-img
        class="page-header header-filter  header-small "
        max-height="250px"
        lazy-src="/media/biz-header.jpg"
        src="/media/test-biz-header.png"
        gradient="to top right, rgba(0,0,0,.53), rgba(25,32,72,.7)"
      >
        <div class="container " style="padding-bottom: 65px;">
          <div class="md-layout justify-md-center ">
            <div
              class="md-layout-item md-size-66 md-small-size-100 mt-35 text-center"
            >
              <div class="lato-header  font-weight-bolder text-white"></div>
              <div class="mt-5">
                <h4 class="text-white "></h4>
              </div>
            </div>
          </div>
        </div>
      </v-img>
    </div>

    <div class="main main-raised" style="background:#fff;">
      <div class="container">
        <div class="text-lg-right">
          <v-btn
            elevation="9"
            rounded
            color="grey"
            @click="$router.push('/market/order-detail/' + $route.params.id)"
          >
            <v-icon left>
              mdi-arrow-left
            </v-icon>
            Back
          </v-btn>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="container">
            <div>Sold To: {{ seller_company_name }}</div>
            <div class="row ">
              <div class="col-lg-4">
                <v-card class="card card-custom card-stretch">
                  <div class="card-header">
                    <div class="card-title">
                      <h3 class="card-label">Billing Info</h3>
                    </div>
                  </div>
                  <div class="card-body">
                    <!-- <h5 class="font-family-primary font-weight-semibold">Great Local Dealer</h5> -->

                    <p class="mb-2">
                      {{ billto.company_name }}<br />
                      {{ billto.address_line1 }}<br />
                      {{ billto.address_line2 }}<br />
                      {{ billto.city }} {{ billto.state }} {{ billto.zip_code }}
                    </p>
                    <p class="mb-2">
                      <span class="font-weight-semibold mr-2">Phone:</span>
                      (123) 456-7890
                    </p>
                  </div>
                </v-card>
              </div>
              <!-- end col -->
              <div class="col-lg-4">
                <v-card class="card card-custom card-stretch">
                  <div class="card-header">
                    <div class="card-title">
                      <h3 class="card-label">Shipping Info</h3>
                    </div>
                  </div>
                  <div class="card-body">
                    <!-- <h5 class="font-family-primary font-weight-semibold">Great Local Dealer Recieving</h5> -->

                    <p class="mb-2">
                      {{ shipto.company_name }}<br />
                      {{ shipto.address_line1 }}<br />
                      {{ shipto.address_line2 }}<br />
                      {{ shipto.city }} {{ shipto.state }} {{ shipto.zip_code }}
                    </p>
                    <p class="mb-2">
                      <span class="font-weight-semibold mr-2">Phone:</span>
                      (123) 456-7890
                    </p>
                  </div>
                </v-card>
              </div>
              <!-- end col -->

              <div class="col-lg-4">
                <v-card flat>
                  <ul class="list-unstyled mb-0">
                    <li>
                      <p v-if="orders.length > 0" class="mb-2">
                        <span class="font-weight-semibold mr-2"
                          >Purchase Order #:</span
                        >
                        {{ orders[0].po_number }}
                      </p>
                      <p class="mb-2">
                        <span class="font-weight-semibold mr-2"
                          >Payment Type:</span
                        >
                        NET TERMS
                      </p>
                    </li>
                  </ul>
                </v-card>
              </div>
              <!-- end col -->
            </div>

            <div class="row  ">
              <div class="col-lg-12">
                <v-card class="card card-custom card-stretch">
                  <div class="card-header card-header-tabs-line">
                    <div class="card-title">
                      <h3 class="card-label">Create Invoice</h3>
                    </div>
                    <div class="card-toolbar"></div>
                  </div>

                  <div class="card-body">
                    <!--begin: Datatable-->
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th class="text-left">Line ID</th>
                          <th style="width:180px;">SKU</th>
                          <th>Thumb</th>
                          <th style="width:180px;">Item</th>
                          <th class="text-left">Price</th>
                          <th style="width:150px;">Qty</th>
                          <th class="text-left">Qty to Invoice</th>
                          <th class="text-left">Subtotal</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, idx) in orders" :key="idx">
                          <td>{{ idx + 1 }}</td>
                          <td>{{ item.sku_id }}</td>
                          <td>
                            <v-img
                              class="d-flex align-self-center mr-3 rounded-circle"
                              :src="
                                item.url != null
                                  ? baseurl + item.url
                                  : '/media/rollerskate.png'
                              "
                              alt="Generic placeholder image"
                              width="50"
                              height="50"
                            >
                            </v-img>
                          </td>
                          <td>
                            {{ item.product_name }}
                            <span v-if="item.item_name != 'default'"
                              >({{ item.item_name }})</span
                            >
                          </td>
                          <td>{{ item.price | dollar_format }}</td>
                          <td>
                            Ordered {{ item.qty }} <br />
                            Invoiced {{ item.qty_invoiced }} <br />
                            Returned {{ item.qty_returned }}
                          </td>
                          <td>
                            <v-text-field
                              v-model="item.qty_to_invoice"
                              type="number"
                              label="qty to invoice"
                              dense
                              outlined
                              style="width: 100px"
                              @change="updateQty"
                            ></v-text-field>
                          </td>
                          <td>
                            {{
                              (item.price * item.qty_to_invoice) | dollar_format
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="7" style="text-align: right;">
                            Subtotal
                          </td>
                          <td>{{ subtotal | dollar_format }}</td>
                        </tr>
                        <tr>
                          <td colspan="7" style="text-align: right;">
                            Shipping amount
                          </td>
                          <td>{{ shipping_amount | dollar_format }}</td>
                        </tr>
                        <tr>
                          <td colspan="7" style="text-align: right;">Total</td>
                          <td>{{ total | dollar_format }}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                    <!--end: Datatable-->
                    <div class="text-lg-right">
                      <v-btn @click="updateInvoice"> Update </v-btn>
                    </div>
                  </div>
                </v-card>
              </div>
            </div>
            <!-- end row -->
            <!-- <div class="row mt-9">
                           
                        
                            <div class="col-lg-6">
                                <div class="card card-custom bg-light card-stretch gutter-b">
											<div class="card-header border-0">
												<h3 class="card-title font-weight-bold text-dark">Send Vendor Message</h3>
												<div class="card-toolbar">
													<div class="dropdown dropdown-inline" data-toggle="tooltip" title="" data-placement="left" data-original-title="Quick actions">
														<a href="#" class="btn btn-clean btn-hover-light-primary btn-sm btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
															<i class="ki ki-bold-more-hor"></i>
														</a>
														<div class="dropdown-menu dropdown-menu-md dropdown-menu-right" style="">
															<ul class="navi navi-hover py-5">
																<li class="navi-item">
																	<a href="#" class="navi-link">
																		<span class="navi-icon">
																			<i class="flaticon2-drop"></i>
																		</span>
																		<span class="navi-text">New Group</span>
																	</a>
																</li>
																<li class="navi-item">
																	<a href="#" class="navi-link">
																		<span class="navi-icon">
																			<i class="flaticon2-list-3"></i>
																		</span>
																		<span class="navi-text">Contacts</span>
																	</a>
																</li>
																<li class="navi-item">
																	<a href="#" class="navi-link">
																		<span class="navi-icon">
																			<i class="flaticon2-rocket-1"></i>
																		</span>
																		<span class="navi-text">Groups</span>
																		<span class="navi-link-badge">
																			<span class="label label-light-primary label-inline font-weight-bold">new</span>
																		</span>
																	</a>
																</li>
																<li class="navi-item">
																	<a href="#" class="navi-link">
																		<span class="navi-icon">
																			<i class="flaticon2-bell-2"></i>
																		</span>
																		<span class="navi-text">Calls</span>
																	</a>
																</li>
																<li class="navi-item">
																	<a href="#" class="navi-link">
																		<span class="navi-icon">
																			<i class="flaticon2-gear"></i>
																		</span>
																		<span class="navi-text">Settings</span>
																	</a>
																</li>
																<li class="navi-separator my-3"></li>
																<li class="navi-item">
																	<a href="#" class="navi-link">
																		<span class="navi-icon">
																			<i class="flaticon2-magnifier-tool"></i>
																		</span>
																		<span class="navi-text">Help</span>
																	</a>
																</li>
																<li class="navi-item">
																	<a href="#" class="navi-link">
																		<span class="navi-icon">
																			<i class="flaticon2-bell-2"></i>
																		</span>
																		<span class="navi-text">Privacy</span>
																		<span class="navi-link-badge">
																			<span class="label label-light-danger label-rounded font-weight-bold">5</span>
																		</span>
																	</a>
																</li>
															</ul>
														</div>
													</div>
												</div>
											</div>
											<div class="card-body pt-2">
												<form class="form" id="kt_form_1">
													<div class="form-group">
                                                        
                                                                  
                                             
                                                        
<div class="dropdown mb-10">
    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
Message Topic   </button>
    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <a class="dropdown-item" >Backorder</a>
        <a class="dropdown-item" >Tracking</a>
        <a class="dropdown-item" >Defect</a>
    </div>
</div>
                                                        
														<textarea class="form-control border-0" name="memo" rows="4" placeholder="Message"></textarea>
													</div>
													<div class="mt-10">
														<button class="btn btn-primary font-weight-bold">Send</button>
													</div>
												</form>
											</div>
										</div>
                            </div>
        
                            <div class="col-lg-6">
                                <div class="card card-custom card-stretch">
										<v-timeline
        align-top
        dense
      >
        <v-timeline-item
          color="pink"
          small
          v-for="(log, index) in vendor_order_log" :key="index"
        >
          <v-row class="pt-1">
            <v-col cols="3">
              <strong>{{log.created_at | date_format}}</strong>
            </v-col>
            <v-col>
              <strong>{{log.detail}}</strong>
            </v-col>
          </v-row>
        </v-timeline-item>

      </v-timeline>
                                    
                               </div>
                            </div>
        
                        </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  filters: {
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY");
      }
    },
    dollar_format: function(value) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
  },
  data: () => ({
    seller_company_name: "",
    shipping_fixed_rate: 0,
    shipping_fee_type: "item",
    shipping_amount: 0,
    subtotal: 0,
    total: 0,
    baseurl: process.env.VUE_APP_STRAPI_API_URL || "http://localhost:1337",
    dialog: false,
    orderTab: "Information",
    selected: 2,
    model: "tab-2",
    orders: [],
    main_order_date: "",
    billto: [],
    shipto: [],
  }),
  apollo: {
    // vendor_order_invoices: {
    //   client: "ecomClient",
    //   query: gql`
    //     query OrderInvoices($vendor_order_id: Int) {
    //       vendor_order_invoices(vendor_order_id: $vendor_order_id) {
    //         id
    //         order_id
    //         created_at
    //         status
    //         total
    //         supplier_name
    //         seller_name
    //         seller_user_name
    //       }
    //     }
    //   `,
    //   variables() {
    //     return {
    //       vendor_order_id: parseInt(this.$route.params.id),
    //     };
    //   },
    // },
    // vendor_order_detail: {
    //   client: "ecomClient",
    //   query: gql`
    //     query OrderDetail($vendor_order_id: Int) {
    //       vendor_order_detail(vendor_order_id: $vendor_order_id) {
    //         id
    //         supplier
    //         price
    //         qty
    //         qty_invoiced
    //         qty_shipped
    //         qty_returned
    //         item_id
    //         product_id
    //         product_name
    //         item_name
    //         sku_id
    //         supplier_name
    //         url
    //         order_id
    //         status
    //         subtotal
    //         shipping_amount
    //         grand_total
    //         created_at
    //         main_order_id
    //         shipping_type
    //         shipping_fixed_rate
    //         seller_id
    //         po_number
    //       }
    //     }
    //   `,
    //   variables() {
    //     return {
    //       vendor_order_id: parseInt(this.$route.params.id),
    //     };
    //   },
    // },
    // vendor_order_log: {
    //   client: "ecomClient",
    //   query: gql`
    //     query OrderLog($vendor_order_id: Int) {
    //       vendor_order_log(vendor_order_id: $vendor_order_id) {
    //         id
    //         order_id
    //         created_at
    //         detail
    //       }
    //     }
    //   `,
    //   variables() {
    //     return {
    //       vendor_order_id: parseInt(this.$route.params.id),
    //     };
    //   },
    // },
  },

  created() {
    this.getVendorOrderDetail();
    this.$apollo.queries.vendor_order_log.refetch();
  },
  methods: {
    ...mapActions(["getCompanyInfo"]),
    updateQty() {
      let sum = 0,
        shipping_sum = 0;
      if (this.orders.length > 0) {
        for (let i in this.orders) {
          if (
            this.orders[i].qty_to_invoice == undefined ||
            this.orders[i].qty_to_invoice == null
          ) {
            this.orders[i].qty_to_invoice =
              parseInt(this.orders[i].qty) -
              Math.max(
                parseInt(this.orders[i].qty_invoiced),
                parseInt(this.orders[i].qty_returned)
              );
          }
          sum += this.orders[i].qty_to_invoice * this.orders[i].price;
          if (this.shipping_fee_type == "item") {
            shipping_sum +=
              this.orders[i].qty_to_invoice * this.shipping_fixed_rate;
          }
        }
      }
      this.subtotal = sum;
      if (this.shipping_fee_type == "item") {
        this.shipping_amount = shipping_sum;
      }
      this.total = this.subtotal + this.shipping_amount;
    },
    isInt(n) {
      return n % 1 === 0;
    },
    async updateInvoice() {
      let flag = 0,
        item_arr = [],
        isZero = 1;
      for (let i in this.orders) {
        if (!this.isInt(parseFloat(this.orders[i].qty_to_invoice))) {
          flag = 1;
        }
        if (
          parseInt(this.orders[i].qty_to_invoice) >
          parseInt(this.orders[i].qty) -
            Math.max(
              parseInt(this.orders[i].qty_invoiced),
              parseInt(this.orders[i].qty_returned)
            )
        ) {
          flag = 1;
        }
        if (parseInt(this.orders[i].qty_to_invoice) != 0) {
          isZero = 0;
        }
        item_arr.push(
          this.orders[i].id +
            "-" +
            this.orders[i].qty_to_invoice +
            "-" +
            this.orders[i].item_name +
            "-" +
            this.orders[i].sku_id +
            "-" +
            this.orders[i].item_id
        );
      }
      if (flag == 1) {
        Swal.fire({
          title: "",
          text: "Invalid qty",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
        });
      } else if (isZero == 1) {
        Swal.fire({
          title: "",
          text: "Empty Invoice",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
        });
      } else {
        // let billto_address = "",
        //   shipto_address = "";
        // if (this.billto.id != undefined && this.billto.id != null) {
        //   billto_address = this.billto.id;
        // }
        // if (this.shipto.id != undefined && this.shipto.id != null) {
        //   shipto_address = this.shipto.id;
        // }
        // let res = await this.$apollo.mutate({
        //   client: "ecomClient",
        //   mutation: gql`
        //     mutation AddInvoiceMutation(
        //       $order_id: Int!
        //       $item_str: String!
        //       $subtotal: Float!
        //       $shipping_amount: Float!
        //       $total: Float!
        //       $billto_id: Int!
        //       $shipto_id: Int!
        //     ) {
        //       addInvoice(
        //         order_id: $order_id
        //         item_str: $item_str
        //         subtotal: $subtotal
        //         shipping_amount: $shipping_amount
        //         total: $total
        //         billto_id: $billto_id
        //         shipto_id: $shipto_id
        //       )
        //     }
        //   `,
        //   variables: {
        //     order_id: parseInt(this.$route.params.id),
        //     item_str: item_arr.join("_"),
        //     subtotal: this.subtotal,
        //     shipping_amount: this.shipping_amount,
        //     total: this.total,
        //     billto_id: billto_address,
        //     shipto_id: shipto_address,
        //   },
        // });
        // if (res.data.addInvoice == true) {
        //   Swal.fire({
        //     title: "",
        //     text: "Updated successfully.",
        //     icon: "success",
        //     confirmButtonClass: "btn btn-secondary",
        //   });
        //   this.$router.push("/market/order-detail/" + this.$route.params.id);
        // }
      }
    },
    async getVendorOrderDetail() {
      await this.$apollo.queries.vendor_order_detail.refetch();
      let order_detail = this.vendor_order_detail;
      if (order_detail.length > 0) {
        this.main_order_date = order_detail[0].created_at;
        this.shipping_fixed_rate = order_detail[0].shipping_fixed_rate;
        this.shipping_fee_type = order_detail[0].shipping_type;
        //get order address
        // let ares = await this.$apollo.query({
        //   client: "ecomClient",
        //   query: gql`
        //     query OrderAddress($main_order_id: Int) {
        //       order_address: order_address(main_order_id: $main_order_id) {
        //         id
        //         main_order_id
        //         address_line1
        //         address_line2
        //         city
        //         state
        //         zip_code
        //         address_type
        //         company_name
        //         first_name
        //         last_name
        //       }
        //     }
        //   `,
        //   variables: {
        //     main_order_id: parseInt(order_detail[0].main_order_id),
        //   },
        // });
        // let address_arr = ares.data.order_address;
        // for (let i in address_arr) {
        //   if (address_arr[i].address_type == "billto") {
        //     this.billto = address_arr[i];
        //   } else {
        //     this.shipto = address_arr[i];
        //   }
        // }
      }
      this.orders = order_detail;

      if (this.orders[0] != undefined && this.orders[0] != null) {
        let res = await this.getCompanyInfo({
          id: this.orders[0].seller_id,
        });
        this.seller_company_name = res.data.business_profile.company_name;
      }

      this.updateQty();
      await this.$apollo.queries.vendor_order_invoices.refetch();
      if (
        this.shipping_fee_type != "item" &&
        this.vendor_order_invoices.length == 0 &&
        this.orders.length > 0
      ) {
        this.shipping_amount = this.orders[0].shipping_amount;
      }
    },
  },
};
</script>
